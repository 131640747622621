export function replaceBasePath(url: string) {
  return url.replace(new URL(url).origin, '');
}

export function assetPath(path: string) {
  const config = useRuntimeConfig();
  return config.public.cms_url + path;
}

export function getCmsImage(image: any): string {
  if (!image) return assetPath('#');
  return assetPath(image[0]?.url ?? '#');
}

export function getPsImage(path: any): string {
  const config = useRuntimeConfig();
  return config.public.prestashop_url + String(path);
}
